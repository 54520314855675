
// Commentaires
#isso-thread {
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

  .isso-comment-header a {
    text-decoration: none; }

  padding: 0;
  margin: 0;

  > {
    h4 {
      color: #555;
      font-weight: bold; }

    .isso-feedlink {
      float: right;
      padding-left: 1em;

      > a {
        font-size: 0.8em;
        vertical-align: bottom; } } }

  .textarea {
    min-height: 7rem;
    outline: 0;

    &.placeholder {
      color: #757575; } } }

#isso-root {
  .isso-comment {
    max-width: 68em;
    padding-top: 0.95em;
    margin: 0.95em auto; }

  .preview .isso-comment {
    padding-top: 0;
    margin: 0; }

  .isso-comment:not(:first-of-type) {
    border-top: 1px solid rgba(0, 0, 0, 0.1); } }

.isso-follow-up .isso-comment {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.isso-comment {
  > div {
    &.avatar {
      display: block;
      float: left;
      width: 7%;
      margin: 3px 15px 0 0;

      > svg {
        max-width: 48px;
        max-height: 48px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); } }

    &.text-wrapper {
      display: block; } }

  .isso-follow-up {
    padding-left: calc(7% + 20px); }

  > div.text-wrapper > {
    .isso-comment-header, .isso-comment-footer {
      font-size: 0.95em; }

    .isso-comment-header {
      font-size: 0.85em;

      .spacer {
        padding: 0 6px;
        color: gray !important;
        font-weight: normal;
        text-shadow: none !important; }

      a.permalink, .note, a.parent {
        color: gray !important;
        font-weight: normal;
        text-shadow: none !important; }

      .spacer:hover, a.permalink:hover, .note:hover, a.parent:hover {
        color: #606060 !important; }

      .note {
        float: right; }

      .author {
        font-weight: bold;
        color: #555; } }

    .textarea-wrapper {
      .textarea, .preview {
        margin-top: 0.2em; } }

    div {
      &.text {
        p {
          margin-top: 0.2em;

          &:last-child {
            margin-bottom: 0.2em; } }

        h1, h2, h3, h4, h5, h6 {
          font-size: 130%;
          font-weight: bold; } }

      &.textarea-wrapper {
        .textarea, .preview {
          width: 100%;
          border: 1px solid #f0f0f0;
          border-radius: 2px;
          box-shadow: 0 0 2px #888; } } }

    .isso-comment-footer {
      font-size: 0.80em;
      color: gray !important;
      clear: left; } } }

.isso-feedlink, .isso-comment > div.text-wrapper > .isso-comment-footer a {
  font-weight: bold;
  text-decoration: none; }

.isso-feedlink:hover {
  color: #111111 !important;
  text-shadow: #aaaaaa 0 0 1px !important; }

.isso-comment {
  > div.text-wrapper > .isso-comment-footer {
    a:hover {
      color: #111111 !important;
      text-shadow: #aaaaaa 0 0 1px !important; }

    > a {
      position: relative;
      top: .2em;

      + a {
        padding-left: 1em; } }

    .votes {
      color: gray; }

    .upvote svg, .downvote svg {
      position: relative;
      top: .2em; } }

  .isso-postbox {
    margin-top: 0.8em; }

  &.isso-no-votes > * > .isso-comment-footer span.votes {
    display: none; } }

.isso-postbox {
  max-width: 68em;
  margin: 0 auto 2em;
  clear: right;

  > .form-wrapper {
    display: block;
    padding: 0;

    > .auth-section {
      display: block;

      .post-action {
        display: block; } }

    .textarea, .preview {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      background: var(--haze) !important;
      border-radius: 0.5rem;
      position: relative;
      padding: 0 1rem;
      margin: 1rem auto 1rem auto;
      border: 1px groove #999999;
      box-shadow: none; }

    input[type=checkbox] {
      vertical-align: middle;
      position: relative;
      bottom: 1px;
      margin-left: 0; }

    .notification-section {
      font-size: 0.90em;
      padding-top: .3em; } } }

#isso-thread {
  .textarea:focus, input:focus {
    border-color: rgba(0, 0, 0, 0.8); } }

.isso-postbox {
  > .form-wrapper {
    > .auth-section {
      .input-wrapper {
        display: inline-block;
        position: relative;
        max-width: 25%;
        margin: 0;
        margin-right: 1rem;
        input {
          line-height: 2rem;
          padding-left: 1rem;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          background: var(--haze) !important;
          border-radius: 0.5rem;
          position: relative;
          border: 1px groove #999999;
          box-shadow: none; } }

      .post-action {
        display: inline-block;
        margin-left: 1rem;
        padding: 0 0 0 0; } }


    .preview, input[name="edit"] {
      display: none; } }

  &.preview-mode > .form-wrapper {
    input[name="preview"], .textarea {
      display: none; }

    .preview {
      display: block; }

    input[name="edit"] {
      display: inline; } }

  > .form-wrapper {
    .preview {
      background-color: #f8f8f8;
      background: repeating-linear-gradient(-45deg, #f8f8f8, #f8f8f8 10px, #fff 10px, #fff 20px); }

    > .notification-section {
      display: none;
      padding-bottom: 10px; } } }

@media screen and (max-width: 600px) {
  .isso-postbox > .form-wrapper > .auth-section .input-wrapper {
    display: block;
    max-width: 100%;
    margin: 0 0 .3em;

    input {
      width: 100%; } } }

.post-action input {
  background-color: $theme;
  color: $light;
  display: inline-flex;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  border: 1px solid $theme;
  border-radius: 1.5rem;
  font-size: 0.9rem;
  align-items: center;
  user-select: none;
  &:hover, &:focus {
    background-color: darken($theme, 20%);
    color: $light;
    border: 1px solid darken($theme, 20%); }

  &_translucent {
    background-color: rgba($theme, 0.15);
    color: $theme;
    border: 1px solid transparent; }
  &_tally {
    padding: 0 0.75rem;
    border-radius: 0.5rem;
    background-color: $theme;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $light;
    margin: 0.1rem 0 0.1rem 0.2rem;
    font-size: 0.7rem; } }

// ligne horizontale pour les commentaires
#separate_comments {
  background-color: #D38DAF;
  margin-top: 3rem;
  margin-bottom: 1rem; }

// rgpd banner
.cookie-banner {
  background-color: #6A123D;
  color: #fafafa;
  position: fixed;
  padding: 0.8rem;
  z-index: 99;
  width: 100%;
  height: 2rem;
  bottom: 0;
  left: 0;
  @media screen and (max-width: 900px) {
    height: 5rem; }
  @media screen and (max-width: 600px) {
    height: 8rem; }
  @media screen and (max-width: 300px) {
    height: 12rem; } }

.cookie-banner p {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @media screen and (max-width: 900px) {
    top: 0;
    transform: translateY(-5%); }
  @media screen and (max-width: 600px) {
    top: 0;
    transform: translateY(-5%); }
  @media screen and (max-width: 300px) {
    top: 0;
    transform: translateY(-0%); } }

.js-cookie-dismiss, .rgpd_link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5rem;
  padding: 0.25rem 1rem;
  font-size: 0.70rem;
  @media screen and (max-width: 900px) {
    transform:  translateY(0%);
    top: 60%;
    padding: 0.15rem 0.75rem; }
  @media screen and (max-width: 600px) {
    transform:  translateY(0%);
    top: 75%;
    padding: 0.15rem 0.5rem; }
  @media screen and (max-width: 300px) {
    transform:  translateY(0%);
    top: 80%;
    padding: 0.15rem 0.5rem; } }

.rgpd_link {
  right: 6rem; }

// Titre
h1 {
  font-size: 150%;
  font-weight: 400; }
h2 {
  font-size: 140%;
  text-decoration: underline;
  padding-top: 3rem;
  margin-top: -2rem; }
h3 {
  font-size: 120%;
  margin-left: 0.7rem; }
h4 {
  font-size: 100%;
  margin-left: 1.4rem;
  font-style: italic; }
h5 {
  font-size: 100%;
  text-decoration: underline;
  margin-left: 2.1rem; }
h6 {
  font-size: 100%;
  margin-left: 2.8rem; }

.excerpt_header {
  .post_link a {
    font-size: 140%;
    font-weight: 400; } }

.excerpt {
  h2 {
    font-size: 110%;
    text-decoration: underline; }
  h3 {
    font-size: 100%;
    margin-left: 0.7rem; }
  h4 {
    font-size: 90%;
    margin-left: 1.4rem;
    font-style: italic; }
  h5 {
    font-size: 90%;
    text-decoration: underline;
    margin-left: 2.1rem; }
  h6 {
    font-size: 90%;
    margin-left: 2.8rem; }
  p {
    font-size: 90%; } }

.footer_links {
  font-size: 70%;
  float: right; }

// fonts
@font-face {
  font-family: 'Metropolis';
  font-style: italic;
  font-weight: 700;
  src: local('Metropolis Bold Italic'), local('Metropolis-BoldItalic'), url('#{$font-path}/Metropolis-BoldItalic.woff2') format('woff2'), url('#{$font-path}/Metropolis-BoldItalic.woff') format('woff');
  font-display: swap; }
